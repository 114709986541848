import React from "react";

const NotebookBase = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="28.153"
      viewBox="0 0 914.93 28.153"
    >
      <path
        id="Path_1"
        data-name="Path 1"
        d="M454.909,299.706l-.294.162-.177.156-.233.239-.119.242-.175.318-.058.321V310l.119.318.175.239.236.238.994.639,1.643.878,2.346,1.036,1.524.557,1.762.639,1.933.719,2.288.636,2.465.719,2.815.719,3.051.719,3.4.795,3.7.719,4.048.719,4.4.719,4.809.8,5.1.636,5.572.719,5.924.639,6.335.636,6.8.56,7.217.56,7.68.477,8.153.48,8.679.394,9.152.318,9.617.242,10.205.239,10.733.083,11.26.079h606.668l11.26-.079,10.733-.083,10.147-.239,9.675-.242,9.15-.318,8.623-.394,8.21-.48,7.687-.477,7.213-.56,6.8-.56,6.338-.636,5.924-.639,5.509-.719,5.163-.636,4.75-.8,4.4-.719,4.048-.719,3.751-.719,3.406-.795,3.046-.719,2.815-.719,2.465-.719,2.229-.636,1.991-.719,1.762-.639,1.466-.557,2.4-1.036,1.643-.878.994-.639.234-.238.177-.239.061-.318.058-.4v-8.452l-.119-.321-.117-.318-.175-.242-.175-.239-.236-.156-.236-.162Z"
        transform="translate(-453.852 -299.706)"
        fill="#191919"
        // fillRule="evenodd"
      />
    </svg>
  );
};

export default NotebookBase;
